@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/material.css';
html,
body {
  font-family: Montserrat, monospace;
  height : 105vh;
}

.listitem-table {
}
.CodeMirror * {
/*          ^
*/
  font-size: 1rem;
}